import React from 'react'
import BC from '../../Menservises/MenIaac/BC'
import First from '../../Menservises/MenIaac/First'
import { Helmet } from 'react-helmet-async'
const IaC = () => {
 
  return (
    <>
    <Helmet>
      <title>Infra as Code</title>
      <meta name="description" content="SkyOps provide Infrastructure as Code (IaC) solutions that streamline deployment,enhance scalability. We provide Terraform and cloudFormation with efficient way"  data-rh="true"/>
      <link rel="canonical" href="https://skyops.io/IaC"  />    
    </Helmet>
      <BC Title="Infrastructure as a Code" Breadcumb="SERVICES" />
      <First/>
    </>)
}

export default IaC
