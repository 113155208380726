import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import BC from '../../Menservises/MenAWS/BC'
import First from '../../Menservises/MenAWS/First'
import { Helmet } from 'react-helmet-async'
const AWS = () => {

	return (
		<>
		<Helmet>
			<title>Amazon Web Services</title>
			<meta name="description" content="Unlock the Full Potential of AWS with SkyOps and explore tailored DevOps solutions leveraging the vast capabilities of Amazon Web Services with our expertise."  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/AWS"  /> 
		</Helmet>
			<BC Title="Amazon Web Services" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default AWS