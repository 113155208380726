import React from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact-one-bg-1-1.png'
import MyForm from './MyForm'

function With() {
    return (
        <>
            <section className="contact-one section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            {/* <form className="contact-one__form contact-form-validated">
                                <div className="section-title">
                                    <p className="section-title__text">Contact with us</p>
                                    <h2 className="section-title__title">Join Us To Get Free
                                        Consultations</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12">
                                        <input type="text" placeholder="Your name" name="name" />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input type="email" placeholder="Email address" name="email" />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <textarea name="message" placeholder="Write message"></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="thm-btn contact-one__btn" type="submit">
                                            <span>send message</span>
                                        </button>
                                    </div>
                                </div>
                            </form> */}
                            <MyForm/>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms" style={{ backgroundImage: `url(${BG})` }}>
                                <div className="contact-one__item">
                                    <strong className="contact-one__item__title">Address</strong>
                                    <p className="contact-one__item__text">Alpha Techno Square, Old Airport <br />
                                        Islamabad, Pakistan</p>
                                </div>
                                <div className="contact-one__item">
                                    <strong className="contact-one__item__title">Phone</strong>
                                    <p className="contact-one__item__text">Local: <Link to="tel:051">051-8742704</Link><br />
                                        Mobile: <Link to="tel:+923215533938">+92 3215533938</Link><br/>Mobile: <Link to="tel:+923105614224">+92 3105614224</Link></p>
                                </div>
                                <div className="contact-one__item">
                                    <strong className="contact-one__item__title">Email</strong>
                                    <p className="contact-one__item__text">
                                        <Link to="mailto:info@skyops.io">info@skyops.io</Link><br />
                                    </p>
                                </div>
                                <div className="contact-one__item">
                                    <ul className="contact-one__social">
                                        <li><Link to="https://www.linkedin.com/company/skyops-io/"><i className="fab fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default With