import React from 'react'
import { Link } from 'react-router-dom'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

function Provide() {

    const options = {
        loop: true,
        margin: 0,
        items: 1,
        modules:[Navigation],
        navigation:true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 30
            },
            1200: {
                slidesPerView: 5,
                spaceBetween: 30
            }
        },
    };

    const services = [
        {
            icon: 'icon-new-product',
            title: 'IaC',
            text: 'Providing the solutions for IaC.',
            link: '/IaC',
        },
        {
            icon: 'icon-cloud',
            title: 'Cost Optimization',
            text: 'Providing the solutions for Cost-Efficiency.',
            link: '/CostOptimization',
        },
        {
            icon: 'icon-new-product',
            title: 'AWS Cloud',
            text: 'Providing the solutions for Amazon web-services.',
            link: '/AWS',
        },
        {
            icon: 'fa fa-lock',
            title: 'Cloud Security',
            text: 'Providing the solutions for security.',
            link: '/CloudSecurity',
        },
        {
            icon: 'icon-protection',
            title: 'Container Orchestration',
            text: 'Solution for Containers',
            link: '/ContainerOrchestration',
        },
        {
            icon: 'fab fa-m',
            title: 'Cloud Migration',
            text: 'Providing the solutions for migaration.',
            link: '/CloudMigration',
        },
            
        {
            icon: 'icon-web-development',
            title: 'CI/CD Pipelines',
            text: 'Providing the solutions Pipeline',
            link: '/Pipeline',
        },
        {
            icon: 'fab fa-a',
            title: 'Azure Cloud',
            text: 'Providing the Azure solutions',
            link: '/Azure',
        },
        {
            icon: 'fab fa-google',
            title: 'Google Cloud',
            text: 'Providing Google Cloud solutions',
            link: '/GCP',
        },
    
    ];

    return (
        <>
            <section className="section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                style={{ backgroundImage: `url(${BGImg3})` }}>
                <div className="container">
                    <div className="section-title text-center">
                        <h4 className="section-title__text">Popular Services</h4>
                        <h5 className="section-title__title">We Provide Our Client with the <br /> Best Services</h5>

                    </div>
                    <Swiper className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options} spaceBetween={30}>
                        {services.map((service , index )=> (
                            <SwiperSlide className="item" key={index}>
                                <div className="service-card-three">
                                    <div className="service-card-three__icon">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="service-card-three__content">
                                        <strong className="service-card-three__title"><Link to={service.link}>{service.title}</Link></strong>
                                        <div className="service-card-three__text">{service.text}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Provide
