import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Data from '../../Menprojectdeatils/Data'
import { Helmet } from 'react-helmet-async'
const GenerativeAI = () => {
	return (
		<>
		<Helmet>
			<title>Project | GenerativeAI</title>
			<meta name="description" content="Unlock the Power of Generative AI with SkyOps. Explore our Generative AI project to discover how cutting-edge technology meets DevOps excellence."  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/GenerativeAI" />  
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title={<h1 style={{fontWeight:"bold", fontSize:"48px"}}>Generative AI application deployment using ECS and API Gateway</h1>} Breadcumb="PROJECT" />
		</div>
			<Data/>
			
		</>
	)
}

export default GenerativeAI