import React from 'react'
import { Link } from 'react-router-dom'
import image from './2.3.png'
// import Carousel from './Carousel'

function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">
                    {/* <div className="project-details__image">
                        <img src={image} alt="" />
                    </div> */}
                    <div className="project-details__info" style={{width:"auto"}}>
                        

                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Category:</strong>
                            <p className="project-details__info__text"><Link to="#"></Link> <Link to="#">ECS and API Gateway</Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Tools</strong>
                            <p className="project-details__info__text">Docker, Terraform cloud, AWS CI/CD-pipeline, <br/>IAM, SSO, Dynatrace, Akamai, Strapi, Redis, RDS</p>
                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Date:</strong>
                            <p className="project-details__info__text">28 August, 2023</p>
                        </div>
                           
                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="project-details__title">Provision infrastructure for Application</h2>
                    <div className="project-details__content">
                    <div style={{ "display": "flex" }}>
                        <p style={{width:"100%",textAlign: "justify", display:"inline-block"}}>This project involves setting up a robust infrastructure and an automated deployment pipeline for an E-commerce application. 
                        It focuses on creating a scalable and reliable environment while ensuring seamless integration of code changes and efficient 
                        deployment processes. The project emphasizes secure data handling, automated monitoring, and a streamlined pipeline for consistent 
                        application updates. It aims to orchestrate a highly adaptable and scalable architecture, optimizing performance and automating 
                        monitoring for a seamless customer experience. The infrastructure provisioning focuses on establishing a secure and scalable 
                        foundation for the E-commerce application, ensuring reliability and adaptability to varying workloads. </p>
{/* 
                        <Carousel/> */}
                        </div>
                        <p style={{textAlign: "justify", display:"inline-block"}}>Emphasizing secure data management and automated monitoring, the focus is on constructing a dynamic and adaptable 
                        architecture. This aims to optimize performance and automate monitoring for a robust user experience. The infrastructure
                        provisioning aims to lay a secure and scalable groundwork, ensuring reliability and adaptability to accommodate the 
                        evolving demands of the E-commerce platform.</p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Infrastructure Provisioning
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                CI/CD Pipeline Implementation
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Security and Monitoring Integration
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/Web">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/LMS">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Data