import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import BC from '../../Menservises/Menpipeline/BC'
import First from '../../Menservises/Menpipeline/First'
import { Helmet } from 'react-helmet-async'
const Pipeline = () => {

	return (
		<>
		<Helmet>
			<title>CI/CD Pipeline</title>
			<meta name="description" content="Explore how SkyOps orchestrates CI/CD  deployment, swift and reliable software delivery, where innovation meets precision in the world of DevOps services.."  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/Pipeline"  />   
		</Helmet>
			<BC Title ="CI/CD Pipeline" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default Pipeline