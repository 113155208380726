import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './AWS.png'
import image2 from './AWS-benefits.png'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. What differentiates Azure from AWS or Google Cloud?",
			answer: "Azure integrates seamlessly with Microsoft tools and services, making it favorable for businesses entrenched in the Microsoft ecosystem, while AWS and Google Cloud offer different strengths like extensive services and a focus on data analytics, respectively."
		},
		{
			question: "2. How secure is Azure?",
			answer: "Azure ensures robust security with encryption, multi-factor authentication, compliance certifications, and regular updates, prioritizing the protection of user data."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Azure" />
								</div>
								<h1 className="service-details__title" style={{marginBottom:"12px"}}>Unlocking Potential: Azure Cloud Solutions</h1>
								<div className="service-details__content">
									<p style={{textAlign: "justify"}}>Azure's dynamic suite of solutions transcends boundaries, enabling businesses to unleash
									their full potential. By leveraging Azure's robust infrastructure, organizations harness 
									power of scalability, security, and agility. This platform empowers seamless integration 
									and offers a diverse array of tools—from AI and IoT to analytics—propelling businesses 
									toward unprecedented heights of success. With Azure Cloud Solutions, the keys to innovation
									and growth are firmly within reach, driving enterprises towards their aspirations and beyond.<br/>
									Azure Cloud Solutions serve as the cornerstone for modern businesses, offering not just technological 
									advancements but a pathway to redefining industry standards. With its unparalleled versatility and 
									constant evolution, Azure fuels a culture of innovation, continuously unlocking new possibilities for 
									those ready to explore and excel in the digital realm.</p>
									<h2 className="service-details__box__title" style={{marginBottom:"12px"}}>Azure Cloud: Beyond Boundaries</h2>
									<p style={{textAlign: "justify"}}>Azure transcends traditional confines, offering a dynamic ecosystem for innovation and growth. 
									Its scalable infrastructure, coupled with an extensive array of tools and services, empowers businesses 
									to break free from limitations, explore uncharted territories, and redefine what's achievable in the 
									realm of cloud computing. </p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content">

												<h3 className="service-details__box__title">
													<Link to="#"> Trailblazing Excellence</Link>
												</h3>
												<p className="service-details__box__text">It signifies a commitment to charting new leveraging Azure's robust capabilities.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon fab fa-a"></i>
											<div className="service-details__box__content">
												<h3 className="service-details__box__title">
													<Link to="#">Azure Enthusiast</Link>
												</h3>
												<p className="service-details__box__text">It serves as the cornerstone of our strategic approach, providing the flexibility and innovation.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="Azure" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h4 className="service-details__caption__title" style={{marginBottom:"10px"}}>Our Benefits</h4>
											<p className="service-details__caption__text" style={{marginBottom:"10px"}}>Azure, with its unparalleled scalability and robust security, empowers businesses to innovate seamlessly. </p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Flexibility and Hybrid Capabilities
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Advanced Analytics and AI
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Global Reach and Reliability
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1" style={{textAlign:"justify"}}>
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='azure' />
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First