import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './AWS.png'
import image2 from './AWS-benefits.png'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. Why opt for AWS consultancy services?",
			answer: "AWS consultancy provides expert guidance for leveraging AWS's vast capabilities, optimizing infrastructure, cost-efficiency, and ensuring scalable, secure, and reliable solutions tailored to specific business needs."
		},
		{
			question: "2. What key benefits does AWS consultancy offer?",
			answer: "AWS consultants bring in-depth knowledge to streamline cloud architecture, implement best practices, maximize resource utilization, enhance security measures, and expedite deployment while aligning solutions with business objectives."
		},
		{
			question: "3. How does AWS consultancy contribute to cost-effectiveness?",
			answer: "AWS consultants employ strategies like right-sizing resources, optimizing usage, leveraging cost-effective services, and implementing automation, resulting in reduced expenses, better resource utilization, and improved ROI for businesses."
		},
		{
			question: "4. How does AWS consultancy support migration and adoption?",
			answer: "AWS consultants facilitate seamless migration strategies, ensuring a smooth transition from on-premises systems to the cloud. They provide guidance on application refactoring, data transfer, and adoption best practices, minimizing disruptions and maximizing the benefits of AWS services."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="AWS" />
								</div>
								<h1 className="service-details__title">Optimizing Business Efficiency with AWS Solutions</h1>
								<div className="service-details__content">
									<p style={{textAlign: "justify"}}>At SkyOps, we specialize in leveraging the power of AWS to drive unparalleled 
									efficiency in business operations. Our tailored solutions harness the robust capabilities 
									of Amazon Web Services to optimize workflows, streamline processes, and propel businesses 
									towards peak performance.<br/>
									
									Through meticulous analysis and strategic implementation, we craft scalable and cost-effective 
									AWS architectures designed specifically for your business needs. By harnessing cloud-based 
									innovations, we empower organizations to achieve heightened agility, minimize downtime, and 
									scale resources efficiently.</p>
									<h2 className="service-details__box__title">WHAT WE OFFER</h2>
									<p style={{textAlign: "justify"}}>Reduce your business expenses and enjoy maximum storage capacity with 
									our AWS cloud consulting services with an easy-to-use feature. Amazon Web Services (AWS) 
									is an advanced cloud computing platform provided by Amazon that includes various offerings, 
									such as IaaS, Paas, and IaaS.</p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-consulting"></i>
											<div className="service-details__box__content">

												<h3 className="service-details__box__title">
													<Link to="#">DevOps Consultant</Link>
												</h3>
												<p className="service-details__box__text" style={{width:"110%",textAlign: "justify", display:"inline-block"}}>We have provided DevOps consultancies  to companies for making the development process more efficient and cost-effective</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content">
												<h3 className="service-details__box__title">
													<Link to="#">DevOps Specialist</Link>
												</h3>
												<p className="service-details__box__text" style={{width:"110%",textAlign: "justify", display:"inline-block"}}> We can help you with our experties to add value across your product with our DevOps managed services. 
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="AWS" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h3 className="service-details__caption__title" style={{marginBottom:"12px"}}>Our Benefits</h3>
											<p className="service-details__caption__text" style={{textAlign: "justify", marginBottom:"12px"}} >Embracing AWS solutions with our consultancy not only revolutionizes 
											business efficiency but also unlocks a trifecta of advantages. Scalability and flexibility empower businesses to 
											adapt swiftly to changing needs, optimizing resources for peak performance. </p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Scalability and Flexibility
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Cost Efficiency and Optimization
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Enhanced Security and Compliance
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='aws'/>
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First