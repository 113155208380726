import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import First from '../../Menblog/First'
import Blogdetails from '../../Menblogdeatils/blogdetails'

const Blogs = ({blogs}) => {
  //  console.log(blogs)
    return (
        <>
            <Breadcumb Title="All Blogs" Breadcumb="BLOG" />
            <First blogs={blogs}/>
        </>
    )
}

export default Blogs
