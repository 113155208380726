import React from 'react'
import { Link } from 'react-router-dom'
import image from './image.png'
//import Carousel from './Carousel'

function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">
                    {/*<div className="project-details__image">
                        <img src={image} alt="" />
                    </div>*/}
                    <div className="project-details__info" style={{width:"auto"}}>
                        
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Category:</strong>
                            <p className="project-details__info__text"><Link to="#"></Link> <Link to="#">Website Devlopement</Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Tools</strong>
                            <p className="project-details__info__text">Terraform, Gitops using Argocd, EKS</p>
                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Date:</strong>
                            <p className="project-details__info__text">28 Novemeber, 2023</p>
                        </div>
                           
                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="project-details__title">E-Commerce Website Deployment using SaaS Model</h2>
                    <div className="project-details__content">
                    <div style={{ "display": "flex" }}>
                        <p style={{textAlign:"justify", display:"inline-block"}}>This project revolves around deploying an E-commerce website utilizing a Service as a Solution (SaaS) approach. 
                        It focuses on creating a scalable and adaptable architecture while emphasizing streamlined deployment processes. 
                        The goal is to implement a model that allows users to access and use the E-commerce platform without the complexities 
                        of infrastructure management. Emphasis lies in orchestrating an efficient deployment model that ensures seamless updates 
                        and enhancements to the website. This deployment model aims to provide a user-friendly experience while maintaining 
                        reliability and scalability, ensuring the platform's consistent performance and accessibility for end-users.</p>
                        {/* <Carousel/> */}
                        </div>
                        <p style={{textAlign:"justify", display:"inline-block"}}>Launching an E-commerce website using the SaaS model involves creating a scalable and user-centric platform. 
                        The project emphasizes delivering a hassle-free experience to end-users by abstracting the complexities of 
                        infrastructure management. It aims to orchestrate an adaptable and responsive deployment process, ensuring 
                        seamless updates and improvements to the website. The goal is to provide a reliable and accessible E-commerce 
                        platform that caters to evolving market demands while ensuring consistent performance and usability for customers.
                         </p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Automated Deployment Framework
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Scalable and User-Centric Design
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Abstracted Infrastructure Management
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/Saas">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/GenerativeAI">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Data