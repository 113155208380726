import React from 'react'
import BC from '../../Menservises/MenCO/BC'
import First from '../../Menservises/MenCO/First'
import { Helmet } from 'react-helmet-async'
const CostOptimization= () => {

	return (
		<>
		<Helmet>
			<title>Cost Optimization</title>
			<meta name="description" content="Navigate our Cost Optimization Services for your business. SkyOps specialize in fine-tuning your infrastructure, enhancing efficiency, and maximize cost saving."  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/CostOptimization"  />  
		</Helmet>
			<BC Title="Cost-Optimization" Breadcumb="SERVICES" />
			<First />
		</>
	)
}

export default CostOptimization