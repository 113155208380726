import React, { useEffect, useState } from 'react'
import {useLocation } from 'react-router-dom'
import ErrorPage from '../Notfound/Errorpage';

const Showmaybe = ({children}) => {

const location = useLocation();
const [showNavbar,setShowNavbar] = useState(false)

    useEffect(() => {
   // console.log("this test", location)
    if ( location.pathname === "/ErrorPage" 

    ){
        setShowNavbar(false)
     } else {
        setShowNavbar(true)
         }
    }, [location])


    return (
        <>
       <div>{showNavbar && children}</div>
        </>
    )
}

export default Showmaybe