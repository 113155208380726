import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import BC from '../../Menservises/MenGC/BC'
import First from '../../Menservises/MenGC/First'
import { Helmet } from 'react-helmet-async'
const GCP = () => {

	return (
		<>
		<Helmet>
			<title>Google Cloud Provider</title>
			<meta name="description" content="Explore the power of Google Cloud with us and customized DevOps solutions on this innovative platform and discover how we optimize your journey on Google Cloud."  data-rh="true"/>
    		<link rel="canonical" href="https://skyops.io/GCP"  />   
		</Helmet>
			<BC Title="Google Cloud" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default GCP