import React from 'react'
import { Link } from 'react-router-dom'
import project01 from '../../assets/images/projects/ECS APP.png'
import project02 from '../../assets/images/projects/CICdk.png'
import LM_1 from '../../assets/images/projects/LM/LM_1.png'
import project03 from '../../assets/images/projects/LM.png'
import project04 from '../../assets/images/projects/dummy.png'
import image from '../Project/SaaS/saas.png'
import image1 from '../Project/website/image.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import {View, Text, StyleSheet} from 'react';

function Latestproject() {

    const projectOptions = {
        loop: true,
        leftmargin: 200,
        rightmargin:200,
        items: 1,

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        },
        
        modules: [Navigation], // Add Navigation module
        navigation: true
    }

    return (
        <>
            <section className="section-padding--bottom section-padding--top project-one">
                <div className="container">
                    <div className="project-one__top">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="section-title ">
                                    <p className="section-title__text">Latest Projects</p>
                                    <h5 className="section-title__title">We Have Following <br />Projects</h5>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="project-one__top__content">
                                    <p className="project-one__top__text" style={{textAlign: "justify"}} >Additionally, our innovative DevSecOps offerings integrate robust security practices,
                                        ensuring a secure and compliant software development lifecycle.</p>
                                    <div className="project-one__top__btns">
                                        <Link to="/Projects" className="thm-btn project-one__top__btn">
                                            <span>View Projects</span>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Swiper className="" {...projectOptions} spaceBetween={30}>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image ">
                                    <img src={project01} alt="Generative AI" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solutions</p>
                                        <strong className="project-card-one__title"><Link to="/GenerativeAI">Generative AI application</Link></strong>
                                        <Link to="/GenerativeAI" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image">
                                    <img src={project02} alt="Ecommerce using CICD pilpline" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solutions</p>
                                        <strong className="project-card-one__title"><Link to="/Ecommerce"> E-Commerce Application
                                        </Link></strong>
                                        <Link to="/Ecommerce" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image">
                                    <img src={image} alt="Saas" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solutions</p>
                                        <strong className="project-card-one__title"><Link to="/Saas">Saas Using IaC and CI/CD
                                        </Link></strong>
                                        <Link to="/SaaS" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image">
                                    <img src={image1} alt="E-Commerce Website" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solutions</p>
                                        <strong className="project-card-one__title"><Link to="/Web">E-Commerce Website Deployement
                                        </Link></strong>
                                        <Link to="/Web" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image">
                                    <img src={LM_1} alt="LMS" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solutions</p>
                                        <strong className="project-card-one__title"><Link to="/LMS">Learning Management System</Link></strong>
                                        <Link to="/LMS" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <div className="project-card-one" style={{padding:"10%"}}>
                                <div className="project-card-one__image">
                                    <img src={project04} alt="Migration heroku to aws" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">DevOps & Cloud Solution</p>
                                        <strong className="project-card-one__title"><Link to="/Migration">Migration from Heroku to AWS</Link></strong>
                                        <Link to="/Migration" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Latestproject