import React from 'react'
import { Link } from 'react-router-dom'
import BG3 from '../../assets/images/background/service-1-bg-1.png'

const services = [
  { icon: 'fab fa-google',  title: 'Google Cloud',link: "/GCP", delay: '000ms'},
  { icon: 'icon-cloud', title: 'AWS',link: "/AWS", delay: '100ms' },
  { icon: 'icon-protection', title: 'Cloud Security', link: "/CloudSecurity", delay: '200ms' },
  { icon: 'icon-iot', title: 'Cloud Migration', link: "/CloudMigration", delay: '300ms' },
  { icon: 'icon-smartband', title: 'Azure', link: "/Azure", delay: '400ms' },
  { icon: 'icon-tv', title: 'Cost-Optimization', link: "/CostOptimization", delay: '500ms' },
]

function Overcome() {
  return (
    <>
      <section
        className="section-padding--top section-padding--bottom service-three black-bg"
        style={{ backgroundImage: `url(${BG3})` }}
      >
        <div className="container">
          <div className="service-three__block">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-title">
                  <p className="section-title__text">What We Offer</p>
                  <h2 className="section-title__title section-title__title--light">
                    Help you Overcome your Technology Challenges
                  </h2>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="service-three__block__text">
                We're here to help you conquer your technology challenges with expertise and innovation.
                </div>
              </div>
            </div>
          </div>
          <ul className="service-three__list">
            {services.map((service, index) => (
              <li key={index}
                className="service-three__list__item wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay={service.delay}>
                <i className={service.icon + ' service-three__list__icon'}></i>
                <h3 className="service-three__list__title">
                  <Link to={service.link}>{service.title}</Link>
                </h3>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

export default Overcome
