import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Testblog from '../../Menblogdeatils/blogdetails'

const BlogDetails = ({blogs}) => {
	return (
		<>
			<Breadcumb Title="Blog Details" Breadcumb="BLOG" />
			{/* <First blogs={blogs}/> */}
			<Testblog blog={blogs} />
		</>
	)
}

export default BlogDetails