import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './CiCd.png'
import image2 from './images.jpeg'
import { Helmet } from 'react-helmet-async'

function First() {

    const [clicked, setClicked] = useState(0);
	
  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
				<Helmet>
                 <title>CI/CD Pipeline</title>
                 </Helmet>
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="CI/CD Pipline" />
								</div>
								<h1 className="service-details__title">Efficient Continuous Integration and Deployment Pipeline</h1>
								<div className="service-details__content" style={{textAlign: "justify", whiteSpace:"pre-wrap"}}>
									<p>A Continuous Integration and Continuous Deployment (CI/CD) pipeline automates the software 
									delivery process, encompassing continuous integration, testing, and deployment stages. 
									This streamlined pipeline ensures that code changes are regularly and automatically tested, 
									integrated, and deployed into production environments. By enabling rapid iterations, early bug detection, 
									and consistent delivery, CI/CD pipelines enhance development efficiency while maintaining software quality and reliability.</p>
									<strong className="service-details__caption__text">Streamlined CI/CD Operations</strong>
									<p style={{textAlign: "justify"}}>This approach encompasses continuous integration, where code changes are merged and tested 
									continuously, and continuous deployment, automating the release process. By implementing a 
									streamlined CI/CD pipeline, businesses achieve faster iterations, improved software quality, 
									and enhanced agility, enabling rapid and reliable application delivery to meet ever-evolving user needs.</p>
								</div>
								
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="CI/CD Pipline" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h2 className="service-details__caption__title" style={{marginBottom:"4px"}}>Positive Outcomes of a Streamlined CI/CD Process</h2>
											<p className="service-details__caption__text" style={{marginBottom:"8px"}}>Efficient CI/CD pipelines ensure rapid, reliable, and quality-driven software development cycles.</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Accelerated Development Cycles
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Enhanced Software Quality
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Improved Collaboration and Feedback
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='pipeline'/>
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First