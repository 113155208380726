import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'


const ErrorPage = () => {

    return (
        <>
        <Helmet>
        <title>ErrorPage</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                    The page you’re looking for doesn’t exist.
                  </p>
                <Link to="/" className="btn btn-danger" >Go Home</Link>
            </div>
        </div>
            
        </>
    )
}

export default ErrorPage