import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Data from './Data'
import { Helmet } from 'react-helmet-async'
const Migration = () => {
	
	return (
		<>
		<Helmet>
			<title>Project | MigrationHeroku</title>
			<meta name="description" content="Migration from Heroku to AWS from one cloud platform to another, aiming to enhance scalability, reliability, and control over the application's infrastructure"  data-rh="true"/>
           <link rel="canonical" href="https://skyops.io/Migration" />  
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title= {<h1 style={{fontWeight:"bold", fontSize:"48px"}}>Migration from Heroku to AWS</h1>} Breadcumb="PROJECT" />
			</div>
			<Data/>
			
		</>
	)
}

export default Migration