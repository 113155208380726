// import React from 'react'

// function Map() {
//     return (
//         <>
//             <section className="google-map google-map--contact">
//                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
//                     className="google-map__one" title='gmap' allowFullScreen>
//                 </iframe>
//             </section>
//         </>
//     )
// }

// export default Map

import React from 'react';

function Map() {
    return (
        <>
            <section className="google-map google-map--contact">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.96213403945!2d73.09814067490771!3d33.606286941147744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfebf43faab755%3A0x1d770c634cb7e8ff!2sAlpha%20Techno%20square.!5e0!3m2!1sen!2s!4v1700024609122!5m2!1sen!2s"
                    className="google-map__one" title="gmap" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </section>
        </>
    );
}

export default Map;
