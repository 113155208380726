import React, { useState} from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async'; 
const MyForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const isValidForm = () => {
        if (!formData.name.trim() || !formData.email.trim() || !formData.message.trim()) {
            setError('All fields are required.');
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            setError('Invalid email format.');
            return false;
        }
        return true;
        
    };

   const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };
   const [inputValue,  setInputValue] = useState('');
   const [inputValue2, setInputValue2]=useState('');
   const [inputValue3, setInputValue3]=useState('');
   
    function sendEmail(e){
      e.preventDefault ();
      emailjs.sendForm("service_hh6zlp4","template_pqks70x",e.target,"NSzWfBkxR15CZNld7").then(res=>{
      console.log(res);
        }).catch(err=> console.log(err));
       setInputValue('');
       setInputValue2('');
       setInputValue3('');
       alert("Your message has been sent successfully")
      }
    //   function handleClick() {
    //     alert('thanks to contact with us!');
    //   }
    return (
        <>
           
            <form className="contact-one__form contact-form-validated"  onSubmit={sendEmail}  >
                <div className="section-title">
                    {/* <p className="section-title__text">Contact with us</p> */}
                    <h1 className="section-title__title" style={{textAlign:"left"}}>Join Us To Get
                        Consultations</h1>
                </div>
                <div className="row" style={{marginBottom:"20px"}}>
                    {/* ... */}
                    <input type="text" placeholder="Your name" id= "name" name="name" required value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                    <input type="email" placeholder="Email address" id="email" name="email" required value={inputValue2} onChange={(e) => setInputValue2(e.target.value)}/>
                    <textarea id="message" name="message" placeholder="Write message" required value={inputValue3} onChange={(e) => setInputValue3(e.target.value)} ></textarea>
                    {/* ... */}
                </div>
                <button className="thm-btn contact-one__btn" type="submit" onSubmit={isValidForm}>
                    <span>send message</span>
                </button>
            </form>
        </>
    );
};

export default MyForm;
