import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './CMigration.png'
import image2  from './cmb.png'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. What are the primary benefits of migrating to the cloud?",
			answer: "Cloud migration offers numerous advantages, including enhanced scalability, cost-efficiency by paying for usage, increased flexibility in resource allocation, improved collaboration through remote accessibility, heightened data security measures, and the ability to leverage advanced technologies and innovation offered by cloud service providers."
		},
		{
			question: "2. What factors should businesses consider before initiating a cloud migration strategy?",
			answer: "Before migrating to the cloud, businesses should assess their current infrastructure, identify suitable cloud models (public, private, or hybrid), evaluate security requirements, consider compliance needs, estimate costs, and plan for potential downtime or disruptions during the migration process."
		},
		{
			question: "3. How can businesses ensure a smooth and successful cloud migration process?",
			answer: "A successful migration hinges on meticulous planning and execution. This includes conducting a comprehensive inventory of assets, prioritizing applications for migration, establishing a phased approach, testing and validating at each stage, training staff, and having contingency plans for unforeseen challenges."
		},
		{
			question: "4. What are the potential challenges or risks associated with cloud migration, and how can they be mitigated?",
			answer: "Challenges during cloud migration may include data security concerns, compatibility issues, application refactoring complexities, and operational disruptions. Mitigation strategies involve robust security measures, conducting thorough compatibility checks, leveraging automation tools, and collaborating closely with experienced cloud migration experts to navigate challenges effectively."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Cloud Migration" />
								</div>
								<h1 className="service-details__title">Optimizing Operations: Cloud Migration Solutions</h1>
								<div className="service-details__content">
									<p style={{textAlign:"justify"}}>Cloud migration solutions are instrumental in streamlining operations by seamlessly transitioning 
									critical workflows and data to cloud-based environments. Our tailored migration strategies harness 
									the power of cloud technologies to optimize operations, enhance scalability, and boost efficiency.
									 By strategically aligning applications and infrastructure with cloud services, businesses experience 
									 improved agility, reduced operational complexities, and increased resource utilization. Our expertise
									  ensures a smooth transition, minimizing disruptions while maximizing the benefits of cloud migration 
									  for operational excellence.</p>

									<h2 className="service-details__box__title">Our Suite of Solutions</h2>
									<p style={{textAlign:'justify'}}>At SkyOps, our suite of solutions is a testament to our commitment to tailored excellence. 
									We offer a diverse array of innovative services meticulously crafted to meet the evolving needs of businesses. 
									From cloud migration and architecture design to seamless integration and ongoing support, our solutions empower
									 organizations to thrive in dynamic landscapes.</p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-consulting"></i>
											<div className="service-details__box__content">

												<h3 className="service-details__box__title">
													<Link to="#">Tailored Cloud Migration Strategies</Link>
												</h3>
												<p className="service-details__box__text">Customized migration plans designed to ensure smooth transitions to cloud-based environments.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content">
												<h3 className="service-details__box__title">
													<Link to="#">Scalable Architecture Design</Link>
												</h3>
												<p className="service-details__box__text">Expertly crafted architectures that optimize resources and drive business agility for sustainable growth.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h4 className="service-details__caption__title" style={{marginBottom:"10px"}}>Our Benefits</h4>
											<p className="service-details__caption__text"  style={{marginBottom:"10px"}}>Innovation and Updates</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Reliability and Redundancy
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Security Measures
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Accessibility and Mobility
												</li>
											</ul>
										</div>
									</div>
										<div className="col-md-6 col-sm-6" style={{marginRight:"6px"}}>
										<img src={image2} alt="cloud migration" />
										</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='cm' />
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First