import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './CMigration.png'
import image2  from './cmb.png'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1.What sets Google Cloud apart from other cloud providers?",
			answer: "Google Cloud emphasizes its data analytics capabilities and AI-driven solutions, backed by Google's infrastructure and global network, distinguishing itself in the cloud landscape."
		},
		{
			question: "4. How secure is Google Cloud's infrastructure?",
			answer: "Google Cloud implements stringent security measures, including encryption, regular audits, and compliance certifications, ensuring robust protection of user data across its services."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Google Cloud Provider" />
								</div>
								<h1 className="service-details__title" style={{textAlign:"justify", marginBottom:"10px"}}>Google Cloud: Fueling Growth & Collaboration</h1>
								<div className="service-details__content" style={{textAlign:"justify"}}>
									<p>With its expansive suite of tools and services, Google Cloud acts as the driving force propelling 
									businesses toward innovation and expansion. It's not just a platform; it's a transformative ecosystem
									fostering collaboration and efficiency. Google Cloud's scalable infrastructure, coupled with cutting-edge 
									solutions like AI, data analytics, and secure networking, empowers organizations to amplify growth strategies
									 while fostering seamless collaboration across teams and geographies. </p>

									<h2 className="service-details__caption__title" style={{textAlign:"justify", marginBottom:"10px"}}>Collaborative Excellence with Google Cloud</h2>
									<p>Google Cloud empowers organizations to not just collaborate efficiently but to thrive, 
									leveraging collective expertise for transformative growth strategies. It stands as a testament 
									to connectivity and agility, enabling businesses to forge new paths and achieve collaborative 
									excellence.</p>
								</div>
							
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
									<img src={image2} alt="Google Cloud" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h3 className="service-details__caption__title" style={{marginBottom:"10px"}}>Our Benefits</h3>
											<p className="service-details__caption__text" style={{marginBottom:"10px"}}> Its robust security measures and advanced analytics empower businesses to drive efficiency and make data-driven decisions with confidence.</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Reliability and Redundancy
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Security Measures
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Accessibility and Mobility
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='google' />
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First