import React from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Project/BC'
import First from '../../Menproject/First'
import BC from '../../Project/BC'
import { Helmet } from 'react-helmet-async'
const Projects = () => {
	return (
		<>
		<Helmet>
			<title>SkyOps | Projects</title>
		 	<meta name="description" content="Explore DevOps Success Stories: Dive into innovation and excellence unveiling completed client projects in our showcase and start your successes journey with us"  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/Projects"  /> 
		</Helmet>
			<BC Title="Projects" Breadcumb="PROJECT" />
			<First/>
		</>
	)
}

export default Projects