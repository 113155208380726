import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
//import Data from '../../Menprojectdeatils/Data'
import Data from './Data'
import { Helmet } from 'react-helmet-async'
const Web = () => {
	
	return (
		<>
		<Helmet>
		<title>Project | EcommerceWeb </title>
		<meta name="description" content="E-Commerce Website Deployment using SaaS Model. It focuses on creating a scalable and adaptable architecture while emphasizing streamlined deployment processes"  data-rh="true"/>
         <link rel="canonical" href="https://skyops.io/Web" /> 
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title= {<h1 style={{fontWeight:"bold", fontSize:"48px"}}>E-Commerce Website Deployment using SaaS Model</h1>} Breadcumb="PROJECT" /> </div>
			<Data/>
		</>
	)
}

export default Web