import React from 'react'
import { Link } from 'react-router-dom'

function Ready() {
    return (
        <>
            <section className="cta-one cta-one--home-three">
                <div className="container">
                    <div className="cta-one__inner  wow fadeInUp" data-wow-duration="1500ms">
                        <div className="cta-one__circle"></div>
                        <div className="section-title ">
                            <h5 className="section-title__title section-title__title--light">Ready to Get Free Consulations <br />
                                for Any Kind of it Solutions?</h5>
                        </div>
                        <div className="cta-one--home-three__btns">
                            <Link to="/Contact" className="thm-btn thm-btn--light cta-one__btn"><span>Get A Quote</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ready