import React from 'react'
import { Link } from 'react-router-dom'
import BGImg1 from '../../assets/images/background/slider-1-1.jpg'
import BGImg2 from '../../assets/images/background/slider-1-2.jpg'
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Helmet } from 'react-helmet-async';
import { Autoplay, Navigation } from 'swiper/modules';
import "swiper/css/autoplay"

function Homeslider() {
    const sliderOptions = {
        loop: true,
        items: 1,
        margin: 0,
        modules:[Navigation, Autoplay],
        navigation:false,
        autoplay: {
            delay: 5000,
          },
    }
    return (
        <>
            <div className="slider-one"  >
                <Swiper className="swiper-slide" {...sliderOptions}  >
                    <SwiperSlide className="item slider-one__slide-1" >
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg1})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                {/* <div className="slider-one__floated lettering-text" >Technology</div> */}
                                <div className="slider-one__title-wrapper">
                                    <h1 className="slider-one__title">Provide DevOps Solutions<br />
                                    & Cloud Services</h1>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/DevOpsServices" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="item slider-one__slide-2">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg2})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                {/* <p className="slider-one__text">Welcome to SkyOps</p> */}
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title" style={{fontSize:"60px"}}>Revolutionize your software delivery <br />
                                    with DevOps excellence</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/About" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}
export default Homeslider