import React from 'react'
import { Link } from 'react-router-dom'
import image from './saas.png'
//import Carousel from './Carousel'
function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">
                   {/* <div className="project-details__image">
                        <img src={image} alt="" />
                    </div>*/}
                    <div className="project-details__info" style={{width:"auto"}}>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Category:</strong>
                            <p className="project-details__info__text"><Link to="#"></Link> <Link to="#">IaC and CICD</Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Tools</strong>
                            <p className="project-details__info__text">Terraform, Github Actions, EKS, <br/>Ansible, Bash and Python, Datadog</p>
                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Date:</strong>
                            <p className="project-details__info__text">28 September, 2023</p>
                        </div>
                           
                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="project-details__title">SaaS using IaC and CICD</h2>
                    <div className="project-details__content">
                    <div style={{ "display": "flex" }}>
                        <p style={{width:"100%",textAlign: "justify"}}>This project aims to develop a Software as a Service (SaaS) application utilizing modern automation and deployment practices. 
                        The focus is on creating a robust and scalable infrastructure while implementing an efficient and automated pipeline for continuous 
                        integration and delivery. Leveraging industry-standard methodologies and scripting languages, the project emphasizes orchestrating 
                        infrastructure components and automating deployment processes. Additionally, the project prioritizes effective monitoring and system 
                        observability, ensuring optimal performance and reliability of the SaaS application. By embracing these modern practices, the project 
                        seeks to build a resilient and scalable SaaS platform tailored to meet evolving user needs.</p>
                        {/* <Carousel/> */}
                  </div>
                        <p style={{textAlign: "justify", display:"inline-block"}}>The goal is to streamline infrastructure management, automate deployment processes, and ensure consistent 
                        performance and reliability through effective monitoring and observability measures. By integrating these practices,
                         the project aims to create a highly adaptable and responsive SaaS platform that caters to evolving user requirements.
                         </p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Infrastructure Orchestration
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Automated Deployment Pipeline
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Comprehensive Monitoring Solutions
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/Migration">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/Web">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Data