import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Data from './Data'
import { Helmet } from 'react-helmet-async'
const Ecommerce = () => {
	
	return (
		<>
		<Helmet>
		<title>Project | Ecommerce </title>
		<meta name="description" content="Provision infrastructure and cicd pipeline for E-Commerce application using DevOps"  data-rh="true"/>
       <link rel="canonical" href="https://skyops.io/Ecommerce" />  
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title= {<h1 style={{fontWeight:"bold", fontSize:"48px"}}>Provision infrastructure and cicd pipeline for <br/> E-Commerce application</h1>}  Breadcumb="PROJECT" /> 
		</div>	
			<Data/>
			
		</>
	)
}

export default Ecommerce