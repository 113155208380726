import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './CO.png'
import image2 from './ben.jpg'
import { Helmet } from 'react-helmet-async'

function First() {

    const [clicked, setClicked] = useState(0);
	
  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
		<Helmet>
          <title>Container Orchestration</title>
            </Helmet>
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Container orchestration" />
								</div>
								<h1 className="service-details__title">Optimizing Container Management and Scaling</h1>
								<div className="service-details__content" style={{textAlign:"justify"}}>
									<p> By employing specialized tools and platforms, container orchestration automates the 
									intricate processes of deploying, scaling, and managing containers, ensuring seamless operation 
									and resource optimization. This approach not only streamlines the deployment of applications but 
									also facilitates efficient resource utilization, load balancing, and automatic scaling, allowing 
									businesses to handle complex application architectures with ease. </p>
									<h2 className="service-details__box__title">Optimized Container Operations</h2>
									<p>This approach employs sophisticated tools and strategies to automate the orchestration process, 
									ensuring seamless deployment and efficient resource allocation. By leveraging containerization, 
									this methodology facilitates agile and scalable application development, allowing for swift deployment 
									across diverse environments while maintaining consistency and reliability in operations.</p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-consulting"></i>
											<div className="service-details__box__content">

												<h3 className="service-details__box__title">
													<Link to="#">Improved Flexibility</Link>
												</h3>
												<p className="service-details__box__text">Provides the flexibility to run applications consistently across various environments, 
												promoting adaptability and portability</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content">
												<h3 className="service-details__box__title">
													<Link to="#">Facilitates DevOps Practices</Link>
												</h3>
												<p className="service-details__box__text">Fosters collaboration between development and operations teams, 
												enabling faster iterations.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="Container Orchestration" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h4 className="service-details__caption__title" style={{marginBottom:"12px"}}>Upsides of Efficient Container Management</h4>
											<p className="service-details__caption__text" style={{marginBottom:"12px"}}>Efficient container management ensures streamlined deployment and optimized 
											resource utilization for scalable and reliable application operations.</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Rapid Deployment
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Resource Optimization
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Scalability and Reliability
												</li>
											</ul>
										</div>
									</div>
								</div>

								
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='container'/>
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First