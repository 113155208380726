import React from 'react'
import { Link } from 'react-router-dom'
import service_2 from './service2.png'

function Business() {
    return (
        <>
            <section className="section-padding--top section-padding--bottom about-two">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">HOW CAN HELP YOU</p>
                                    <h1 className="section-title__title">Cloud Solutions for Transformative Growth</h1>
                                </div>
                                <div className="about-two__text" style={{textAlign:"justify"}}>At SkyOps, we redefine possibilities in the cloud, 
                                offering innovative solutions that propel businesses towards transformative growth. 
                                Our expert team leverages cutting-edge cloud services, providing scalable and secure 
                                architectures tailored to meet the unique needs of modern enterprises. </div>
                                <ul className="about-two__list">
                                    <li>
                                        Going Above and
                                        Beyond
                                    </li>
                                    <li>
                                        Committed to
                                        People First
                                    </li>
                                </ul>
                                {/*<Link to="/about" className="thm-btn about-two__btn"><span>Learn More</span></Link>*/}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-two__image">
                            <img src={service_2} className="wow fadeInUp" data-wow-duration="1500ms"alt="Cloud Services" /> 
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Business