import { data } from 'jquery'
import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const First = ({blogs}) => {
    if (!blogs || !Array.isArray(blogs)) {
        return <div>No blogs available.</div>;
    }
    return (
    <>
        <Helmet>
        <title>SkyOps | Blogs</title>
        </Helmet>
        <section className="section-padding--top section-padding--bottom">
            <div className="container">
                <div className="row gutter-y-30">
                    {blogs.map(blog => (
                        <div 
                            key={blog.id} 
                            className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" 
                            data-wow-duration="1500ms"
                            data-wow-delay="000ms"
                        >
                            <Link to={`/blog/${blog.slug}/`}>
                                <div className="blog-card-one">
                                    <div className="blog-card-one__image">
                                        {/* Uncomment and adjust the src if you want to display the cover image */}
                                        <img src={blog.coverImg} alt={blog.title} />
                                    </div>
                                    <div className="blog-card-one__content">
                                        <div className="blog-card-one__meta">
                                            <div className="blog-card-one__date">
                                                <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                                {blog.date}
                                            </div>
                                        </div>
                                        <h3 className="blog-card-one__title">{blog.title}</h3>
                                        <div className="blog-card-one__more">
                                            Read More
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </>
    );   

}

export default First