import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './Iaaz.png'
import image2 from './tool.jpg'
import { Helmet } from 'react-helmet-async'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. What are the primary advantages of using Infrastructure as Code?",
			answer: "IaC streamlines infrastructure management by automating deployment processes, ensuring consistency, scalability, and reducing error margins through code-driven operations."
		},
		{
			question: "2. How does Infrastructure as Code foster operational efficiency?",
			answer: "By encoding infrastructure configurations into code, IaC promotes automated, consistent deployments, minimizing human error, and enabling rapid scalability, thus enhancing overall operational efficiency."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
		<Helmet>
                 <title>Infra as Code</title>
            </Helmet>
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="nfrastructure as Code" />
								</div>
								<h1 className="service-details__title">Revolutionizing Operations with Infrastructure as Code (IaC)</h1>
								<div className="service-details__content" style={{textAlign: "justify"}} >
									<p>Infrastructure as Code (IaC) stands at the forefront of modernizing and streamlining operational processes. 
									At SkyOps, we harness the power of IaC to transform traditional infrastructure management into automated, 
									scalable, and efficient practices. By encoding infrastructure configurations in scripts and templates, IaC 
									enables the creation and management of IT resources through code. This approach facilitates rapid provisioning, 
									consistent deployments, and enhanced scalability while minimizing manual errors and promoting agility. Our 
									expertise in leveraging IaC empowers businesses to embrace faster, more reliable, and cost-effective solutions, 
									driving innovation and accelerating digital transformations.</p>
									<h2 className="service-details__box__title">Streamlining Operations with IaC</h2>
									<p>IaC revolutionizes operational workflows by enabling the management and provisioning of 
									infrastructure through code. This approach automates resource deployment, ensuring consistency,
									 scalability, and swift adaptation to changing business needs. Embracing IaC not only enhances 
									 efficiency but also reduces human error, facilitating rapid innovation and agility within IT 
									 operations</p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper" >
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-consulting"></i>
											<div className="service-details__box__content"  style={{maxHeight:"300px"}} >

												<strong className="service-details__box__title">
													<Link to="#">Automation of Infrastructure Deployment</Link>
												</strong>
												<p className="service-details__box__text">Streamlining the deployment process by encoding infrastructure 
												configurations into scripts, allowing for swift and consistent resource provisioning.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box"  >
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content" style={{lineHeight:"35px"}}>
												<strong className="service-details__box__title">
													<Link to="#">Consistency and Scalability</Link>
												</strong>
												<p className="service-details__box__text">Ensuring uniformity across deployments and enabling effortless 
												scalability by defining infrastructure in code, promoting reliable and adaptable operations.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="IaC" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h3 className="service-details__caption__title" style={{marginBottom:"8px"}}>Upsides of Implementing IaC</h3>
											<p className="service-details__caption__text" style={{marginBottom:"12px", textAlign:"justify"}}>Transform operational workflows and infrastructure management 
											through code-driven automation with Infrastructure as Code (IaC)</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Automated Deployment Processes
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Enhanced Consistency and Scalability
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Reduced Error Margins
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne={"iaac"}/>
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First