import React from 'react'
import BC from '../../Menservises/MenCM/BC'
import First from '../../Menservises/MenCM/First'
import { Helmet } from 'react-helmet-async'
const CloudMigration = () => {
	
	return (
		<>
		<Helmet>
		<title>Cloud Migration</title>
		<meta name="description" content="Efficient, secure Cloud Migration Service for seamless transitions.We provide on site to cloud migration and from one cloud to another cloud migration services."  data-rh="true"/>
        <link rel="canonical" href="https://skyops.io/CloudMigration"  />   
		</Helmet>
			<BC Title ="Cloud Migration" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default CloudMigration