import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-light.png'

const Footer = () => {
    const location = useLocation();
    const [footerPadding, setfooterPadding] = useState(false);
    useEffect(() => {
      if(location.pathname === "/" || location.pathname === "/Contact "){
        setfooterPadding(true)
      }else{
        setfooterPadding(false)
      }
    }, [location])
    
    return (
        <>
            <div className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo}  height="120" alt="SkyOps" style={{"objectFit":"cover"}}/>
                                </Link>
                                <p className="footer-widget__text">We work with a passion of taking challenges and on creating cutting-edge solutions for streamlined operations.</p>
                                <ul className="footer-widget__social">
                                    <li><Link to="https://www.linkedin.com/company/skyops-io/"><i className="fab fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <strong className="footer-widget__title">Services</strong>
                                <ul className="footer-widget__links">
                                    <li>
                                        <Link to="/CostOptimization">Cost Optimization</Link>
                                    </li>
                                    <li>
                                        <Link to="/CloudMigration">Cloud Migration</Link>
                                    </li>
                                    <li>
                                        <Link to="/CloudServices">Cloud Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/CICDPipeline">CI/CD Pipeline</Link>
                                    </li>
                                    <li>
                                        <Link to="/IaC">Infrastructure as code</Link>
                                    </li>
                                    <li>
                                        <Link to="/ContainerOrchestration">Container Orchestration</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--newsletter">
                                <strong className="footer-widget__title">Subscribe</strong>
                                <p className="footer-widget__text">Sign up to receive the latest updates</p>

                                <form action="#" className="footer-widget__newsletter mc-form"
                                    data-url="ADD_YOUR_MAILCHIMP_FORM_URL_HERE">
                                    <input type="email" name="EMAIL" placeholder="Enter Your Email" />
                                    <Link to="/Contact" className="thm-btn footer-widget__newsletter__btn" ><span>Register</span> </Link>
                                </form>
                                <div className="mc-form__response"></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <strong className="footer-widget__title">Contact</strong>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="tel:+923215533938">+ 92 (321) 5533 938</Link><br/>
                                        <Link to="tel:+923105614221">+ 92 (310) 5614 224</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="mailto:info@skyops.io">info@skyops.io</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        Alpha Techno Square, Old Airport Islamabad. Pakistan
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; 2024 All Copyrights <span className="dynamic-year"></span> Reserved by SkyOps</p>
                </div>
            </div>
        </>
    )
}

export default Footer