import React from 'react'
import Homeslider from '../Menhome/Homeslider'
import Networking from '../Menhome/Networking'
import Provide from '../Menhome/Provide'
import Counter from '../Menhome/Counter'
import Latestproject from '../Menhome/Latestproject'
import Better from '../Menhome/Better'
import Benifit from '../Menhome/Benifit'
import Need from '../Menhome/Need'
import Clinttwo from '../Menhome/Clinttwo'
import { Helmet } from 'react-helmet-async'
const Home = () => {

    return (
        <>
        <div>
        <Helmet>
        <title> SkyOps | Your Cloud and DevOps partner </title>
        <meta name="description" content="Welcome to SkyOps! Provides DevOps and cloud consulting services across the globe.As your DevOps partner, we navigate the digital landscape with precision." data-rh="true"/>
        <link rel="canonical" href="https://skyops.io/"  />
        </Helmet>
            <Homeslider/>
            <Networking/>
            <Provide/>
            <Counter/>
            <Latestproject/>
            <Better/>
            <Benifit/>
            <Clinttwo/>
            <Need/>
            </div>
        </>
    )
}

export default Home