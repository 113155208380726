import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import img1 from '../../assets/images/resources/about-five-1-1.jpg'
import img2 from '../../assets/images/resources/about-five-1-2.jpg'
import AnimatedNumber from "animated-number-react"
import { IoMdClose } from "react-icons/io";
import { Helmet } from 'react-helmet-async';

function Networking() {
{/* <Helmet>
<title> SkyOps - Your Cloud and DevOps partner </title>
<meta name="descripation" content="Welcome to SkyOps! Provides DevOps and cloud consulting services across the globe" data-rh="true"/>
</Helmet> */}
    const [startAnimation, setStartAnimation] = useState(false);
    const numberRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setStartAnimation(true);
                    observer.disconnect(); // Optional: Disconnect the observer if you only want to trigger once
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is in view
        );

        if (numberRef.current) {
            observer.observe(numberRef.current);
        }

        return () => {
            if (numberRef.current) {
                observer.unobserve(numberRef.current);
            }
        };
    }, []);

    const [ytShow, setytShow] = useState(false)
    const [showVideo, setShowVideo] = useState(false);

    // Inline styles
    const modalBackdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(5px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    };

    const videoModalStyle = {
        position: 'relative',
        zIndex: 1001
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        // More styles can be added here
    };

    return (
        <>
            <section className="section-padding--bottom section-padding--top about-five">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-five__images">
                                <div className="about-five__images__shape"></div>
                                <img src={img1} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="Cloud Services" />
                                <img src={img2} alt="DevOps Services" />

                                {/* <div className="about-five__video">
                                    <Link to="#" onClick={() => setytShow(true)} className="video-popup">
                                        <i className="fa fa-play"></i>
                                    </Link>
                                </div> */}
                                <div>
                                    <div className="about-five__video">
                                        <Link to="#" onClick={() => setShowVideo(true)} className="video-popup">
                                            <i className="fa fa-play"></i>
                                        </Link>
                                    </div>

                                    {showVideo && (
                                        <div style={modalBackdropStyle}>
                                            <div style={videoModalStyle}>
                                                <video width="750" height="500" controls autoPlay>
                                                    <source src="/assets/profile_video.mp4" type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <button style={closeButtonStyle} onClick={() => setShowVideo(false)}>
                                                    <IoMdClose />

                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                
                                    <span className="count-text"></span>
                                    Years <br />
                                    Experience 
<div ref={numberRef}>
            {startAnimation && (
                <span className="count-text">
                    <AnimatedNumber value={10} duration={2500} formatValue={(v) => Math.round(v)} />
                </span>
            )}
        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="section-title ">
                                    <h3 className="section-title__title">The Best Consultation
                                        Company</h3>
                                </div>
                                <div className="about-five__text" style={{textAlign: "justify"}} >Since from 2023 Building Your Better DevOps & Cloud Solutions.
                                    Skyops specializes in providing expert advisory services for a seamless technological journey. </div>
                                <div className="about-five__text">"Passionately Tackling Challenges, Forging New Frontiers"</div>
                                <ul className="about-five__box">
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-consulting"></i>
                                        <div className="about-five__box__content">
                                            <strong className="about-five__box__title">DevOps
                                                Consultant</strong>
                                            <p className="about-five__box__text">Smarter Solutions</p>
                                        </div>
                                    </li>
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-chip"></i>
                                        <div className="about-five__box__content">
                                            <strong className="about-five__box__title">Cloud Specialist</strong>
                                            <p className="about-five__box__text">Faster Solutions</p>
                                        </div>
                                    </li>
                                </ul>
                                {/*<div className="about-four__meta">
                                    <div className="about-four__author">
                                        <img src="assets/images/resources/about-four-author-1.jpg" alt="" />
                                        <div className="about-four__author__content">
                                            <h3 className="about-four__author__title">Malik Najmus Siraj</h3>
                                            <div className="about-four__author__designation">Founder CEO</div>
                                        </div>
                                    </div>
                                    <Link to="/About" className="thm-btn about-four__btn"><span>Learn More</span></Link>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        <iframe src="assets/images/video.mp4" title='ytvideo' allowFullScreen></iframe>
                    </div>
                </div>
            }
        </>
    )
}

export default Networking