import React from 'react'
import Breadcumb from '../Breadcumb/Main'
import Company from '../Menabout/Company';
import Bettertwo from '../Menabout/Bettertwo';
import Peoples from '../Menabout/Peoples';
import Testim from '../Menabout/Testim';
import { Helmet } from 'react-helmet-async';
const About = () => {
   
    return (
        <>
     <Helmet> 
        <title>SkyOps | About Us</title>
        <meta name="description" content="Unleashing DevOps Excellence! Elevate your software delivery with SkyOps. Explore the future of DevOps and Cloud Services with us where speed meets quality."  data-rh="true"/>
        <link rel="canonical" href="https://skyops.io/About"  />    
     </Helmet>
     <Breadcumb Title="About Us" Breadcumb="ABOUT" />
            <Company/> 
            <Bettertwo/>
            <Peoples/>
        </>
    )
}

export default About