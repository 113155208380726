import React from 'react'
import clint01 from '../../assets/images/tools/AK.jpg'
import clint02 from '../../assets/images/tools/Git.png'
import clint03 from '../../assets/images/tools/Splunk.png'
import clint04 from '../../assets/images/tools/ELK stack.png'
import clint05 from '../../assets/images/tools/Kubernetes.png'
import clint06 from '../../assets/images/tools/Grafana.png'
import clint07 from '../../assets/images/tools/GitLab.png'
import clint08 from '../../assets/images/tools/Docker.png'




import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function Tool() {

    const logoOptions = {
        loop: true,
        margin: 30,
        items: 2,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }

    return (
        <>
            <div className="client-carousel client-carousel--two">
                <div className="container">
                    <Swiper className="thm-owl__carousel" {...logoOptions} slidesPerView={5}>
                        <SwiperSlide className="item">
                            <img src={clint01} alt="Azure AKS" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint02} alt="Git" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint03} alt="Splunk" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint04} alt="Elastic Stack" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint05} alt="Kubernetes" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint06} alt="Grafana" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint07} alt="GitLab" />
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img src={clint08} alt="Docker" />
                        </SwiperSlide>    
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default Tool