import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Data from './Data'
import { Helmet } from 'react-helmet-async'
const LMS = () => {
	
	return (
		<>
		<Helmet>
			<title>Poject | LMS</title>
			<meta name="description" content="Learning Management System Application Infrastructure Provision and CI/CD Pipeline using DevOps"  data-rh="true"/>
           <link rel="canonical" href="https://skyops.io/LMS" />  
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title= {<h1 style={{fontWeight:"bold", fontSize:"45px"}}>Learning Management System Application Infrastructure Provision and CI/CD Pipeline </h1>} Breadcumb="PROJECT" />
			</div>
			<Data/>
		</>
	)
}

export default LMS