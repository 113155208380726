import React from 'react'
import Breadcumb from '../Breadcumb/Main'
import Map from '../Mencontact/Map'
import With from '../Mencontact/With'
import { Helmet } from 'react-helmet-async'

const Contact = () => {
    return (
        <>
        <Helmet> 
        <title>SkyOps | Contact Us</title>
        <meta name="description" content="Connect with SkyOps! Your Gateway to DevOps Excellence. Explore tailored Cloud and DevOps solutions & services for your business with our experienced team"  data-rh="true"/>
        <link rel='canonical' href='https://skyops.io/Contact'  />    
       </Helmet>
            <Breadcumb Title="Contact Us" Breadcumb="CONTACT" />
            <With/>
            <Map/>
        </>
    )
}

export default Contact