import React from 'react'
import { Link } from 'react-router-dom'
import image from './1.3.png'
//import Carousel from './Carousel'

function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">

                    {/* <div className="project-details__image">
                        <img src={image} alt="" />
                    </div> */}
                    <div className="project-details__info" >

                        <div className="project-details__info__item">
                            <h2 className="project-details__info__title">Category:</h2>
                            <p className="project-details__info__text"><Link to="#">IT</Link>, <Link to="#">ECS and API Gateway</Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <h2 className="project-details__info__title">Tools</h2>
                            <p className="project-details__info__text">Docker, Terraform, ECS, S3, <br />Cloudwatch, RDS, API gateway</p>
                        </div>
                        <div className="project-details__info__item">
                            <h3 className="project-details__info__title">Details</h3>
                            <p className="project-details__info__text">28 July, 2023</p>
                        </div>

                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h4 className="project-details__title">Generative AI application deployment</h4>
                    <div className="project-details__content">
                        <div style={{ "display": "flex" }}>

                            <p style={{width:"100%",textAlign: "justify", display:"inline-block"}}>This project involves deploying a cutting-edge Generative AI application using a robust cloud
                                infrastructure and automated deployment pipeline. Leveraging industry-standard practices, it streamlines
                                the deployment process, enabling seamless application scaling and management. The project focuses on
                                efficient resource utilization, automated monitoring, and secure data handling. It aims to create a
                                high-performing and scalable application architecture, ensuring reliability, scalability, and security.
                                The deployment involves orchestration and automation to deliver an efficient and scalable solution for
                                deploying the Generative AI application. </p>
{/* 
                            <Carousel/> */}
                        </div>
                        <p style={{textAlign: "justify", display:"inline-block"}}>The project aims to orchestrate a highly scalable and reliable architecture, optimizing performance
                            while automating monitoring and ensuring the utmost security. It emphasizes a streamlined deployment
                            process for effectively managing the Generative AI application, ensuring its reliability and scalability
                            in handling diverse workloads.</p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Automated Deployment Pipeline
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Scalable Cloud Infrastructure
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Robust Security Measures
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
                <div className="container">
                    <div className="project-nav__inner">
                        <Link to="/Web">
                            <i className="icon-left-arrow"></i>
                            Previous
                        </Link>
                        <Link to="/Ecommerce">
                            Next
                            <i className="icon-right-arrow"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Data