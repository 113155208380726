import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './CO (2).png'
import image2 from './image2.png'

function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. How does DevOps contribute to cost optimization?",
			answer: "DevOps practices streamline development, deployment, and operations, fostering automation and collaboration. By ensuring faster release cycles, efficient resource utilization, and identifying and fixing issues early in the development pipeline."
		},
		{
			question: "2. What are the key strategies within DevOps for cost optimization?",
			answer: "Within DevOps, strategies like infrastructure as code (IaC), continuous integration/continuous deployment (CI/CD), and cloud-native development facilitate cost optimization. IaC enables efficient and reproducible infrastructure, CI/CD automates testing and deployment."
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Cost Optimization" />
								</div>
								<h1 className="service-details__title" style={{marginBottom:"10px", textAlign:"justify"}}>Strategic Cost Optimization</h1>
								<div className="service-details__content">
									<p style={{textAlign:"justify"}}>Strategic cost optimization lies at the core of our approach, aimed at aligning resources 
									with business objectives. Our meticulous strategies focus on identifying inefficiencies and streamlining 
									expenditures without compromising quality or performance. Through in-depth analysis and tailored solutions, 
									we empower businesses to optimize operational costs, leverage cost-effective technologies, and maximize ROI. 
									Our commitment to strategic cost optimization ensures that every financial decision is aligned with achieving 
									long-term sustainability and growth.</p>
									
									<h2 className="service-details__box__title" style={{marginBottom:"10px"}}>Efficient Resource Utilization Strategies</h2>
									<p style={{textAlign:"justify"}}>We meticulously analyze operational expenses, identifying areas for optimization without compromising 
									quality or performance. Our tailored approach emphasizes efficient resource allocation, leveraging cost-effective 
									technologies, and implementing streamlined processes. By harmonizing value maximization with cost reduction, 
									we empower businesses to achieve higher efficiencies, better returns on investment, and sustainable growth in an 
									increasingly competitive landscape.</p>
								</div>
								<div className="row gutter-y-30 service-details__box-wrapper">
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-consulting"></i>
											<div className="service-details__box__content">

												<h3 className="service-details__box__title">
													<Link to="#">Strategic Technology Adoption</Link>
												</h3>
												<p className="service-details__box__text">Implementing cost-effective technologies and cloud solutions, 
												leveraging pay-as-you-go models, and embracing automation.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__box">
											<i className="service-details__box__icon icon-chip"></i>
											<div className="service-details__box__content">
												<h3 className="service-details__box__title">
													<Link to="#">Efficient Resource Allocation</Link>
												</h3>
												<p className="service-details__box__text">Tailoring resource distribution to 
												match actual needs, avoiding overprovisioning, and optimizing the utilization of available resources.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="Cost Optimization" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h3 className="service-details__caption__title" style={{marginBottom:"10px"}}>Benefits</h3>
											<p className="service-details__caption__text" style={{marginBottom:"10px", textAlign:"justify"}}>Cost optimization drives efficiency, profitability, 
											and strategic resource allocation, fostering sustainable business growth.</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Increased Efficiency
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Improved Profitability
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Competitive Advantage
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='co' />
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First