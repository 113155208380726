import React from 'react'
import { Link } from 'react-router-dom'
import image from './img.png'
//import Carousel from './Carousel'

function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">
                   {/* <div className="project-details__image">
                        <img src={image} alt="" />
                    </div>*/}
                    <div className="project-details__info" style={{width:"auto"}}>
                        

                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Category:</strong>
                            <p className="project-details__info__text"><Link to="#"></Link><Link to="#">Heroku </Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Tools:</strong>
                            <p className="project-details__info__text">Docker, ECS, RDS, Cloudwatch, S3,  <br/>Cloudfront, Lambda, ECR, Terraform, CircleCi</p>
                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Date:</strong>
                            <p className="project-details__info__text">28 October, 2023</p>
                        </div>
                           
                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="project-details__title">Migration from Heroku to AWS</h2>
                    <div className="project-details__content">
                    <div style={{ "display": "flex" }}>
                        <p style={{width:"100%",textAlign: "justify"}}>This migration project involves transitioning a current application from one cloud platform to another, 
                        aiming to enhance scalability, reliability, and control over the application's infrastructure. By orchestrating 
                        a systematic migration plan, the project aims to leverage industry-standard practices and services for efficient 
                        deployment, data management, and system monitoring. The focus is on adopting a containerized approach, optimizing 
                        storage, and improving content delivery through advanced caching mechanisms. Additionally, automating infrastructure 
                        provisioning and deployment through scripting ensures an agile and scalable environment. This migration seeks to 
                        enhance the application's performance, security, and cost-efficiency by leveraging the capabilities of a new cloud 
                        platform. </p>
                        {/* <Carousel/> */}
                        </div>
                        <p style={{ textAlign: "justify" , display: "inline-block"}}>Transitioning the application from one cloud provider to another involves a meticulous process aimed at elevating 
                        the application's performance and resilience. The migration plan emphasizes leveraging modern containerization techniques 
                        and optimizing data storage while ensuring efficient monitoring and management. By implementing advanced caching mechanisms 
                        and optimizing content delivery, the project aims to enhance the user experience. Automation through scripted deployment 
                        processes streamlines the infrastructure setup, fostering a more agile and scalable environment. </p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Cloud Platform Migration
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Containerization Adoption
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Enhanced Infrastructure Management
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/LMS">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/Saas">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Data