import React from 'react'
import { Link } from 'react-router-dom'
import image from './3.3.png'
// import Carousel from './Carousel'
function Data() {
    return (
        <>
            <section className="project-details section-padding--bottom section-padding--top">
                <div className="container">
                   {/* <div className="project-details__image">
                        <img src={image} alt="" />
                    </div>*/}
                    <div className="project-details__info" style={{width:"auto"}}>
                        

                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Category:</strong>
                            <p className="project-details__info__text"><Link to="#"></Link><Link to="#">Infrastructure Provision</Link></p>

                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Tools:</strong>
                            <p className="project-details__info__text">Heroku, Vercel, Github Pipeline, Coolify, Elastic Kubernetes, <br/>API Gateway, Lambda, RDS postgress</p>
                        </div>
                        <div className="project-details__info__item">
                            <strong className="project-details__info__title">Date:</strong>
                            <p className="project-details__info__text">28 September, 2023</p>
                        </div>
                           
                        <div className="project-details__info__item">
                            <div className="project-details__info__social">
                                <Link to="#"><i className="fab fa-github"></i></Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="project-details__title">Learning Management System Application Infrastructure Provision</h2>
                    <div className="project-details__content">
                    <div style={{ "display": "flex" }}>
                        <p style={{textAlign: "justify"}}>This project focuses on setting up a scalable and automated infrastructure for a Learning 
                        Management System (LMS) application. It involves orchestrating an efficient CI/CD 
                        pipeline for seamless code integration and deployment. The emphasis lies in creating a 
                        robust and scalable environment to support the LMS application's needs while ensuring secure 
                        data management and implementing automated monitoring for optimal performance. The project aims 
                        to establish an adaptable architecture that seamlessly integrates updates and enhancements, ensuring 
                        a reliable and responsive platform for effective learning experiences. </p>
                        {/* <Carousel/> */}
</div>
                        <p style={{textAlign: "justify", display: "inline-block"}}> Building the infrastructure and CI/CD pipeline for a Learning Management System (LMS) application entails 
                        creating a scalable and automated environment. The project aims to streamline code integration and deployment 
                        processes while prioritizing a robust and adaptable architecture. Security measures and automated monitoring 
                        systems are key focal points to ensure data protection and optimal system performance. The objective is to 
                        establish an infrastructure that supports the dynamic nature of an LMS, facilitating efficient updates and 
                        enhancements while delivering a dependable and user-friendly platform for effective learning.</p>
                        <ul className="project-details__list">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Scalable Infrastructure Development
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Efficient CI/CD Pipeline Establishment
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Serverless Architecture Utilization
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/Ecommerce">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/Migration">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Data