import React from 'react'
import { Link } from 'react-router-dom'
import BG from '../../../assets/images/services/services-s-cta-1-1.jpg'

const ServiceSidebar = ({selectedOne=""}) => {  
  return (
    <div className="sidebar">
      <div className="sidebar__item sidebar__item--category">
        <strong className="sidebar__title" >Categories</strong>
        <br/><br/>
        <ul className="sidebar__category">
          <li className={"aws" == selectedOne && "bold"}><Link to="/AWS">AWS Consultancy</Link></li>
          <li className={"iaac" == selectedOne && "bold"} ><Link to="/Iac">Infrastructure as Code</Link></li>
          <li className={"pipeline" == selectedOne && "bold"}><Link to="/CICDPipeline">CI/CD Pipeline</Link></li>
          <li className={"co" == selectedOne && "bold"}><Link to="/CostOptimization">Cost Optimization</Link></li>
          <li className={"cm" == selectedOne && "bold"}><Link to="/CloudMigration">Cloud Migration</Link></li>
          <li className={"cs" == selectedOne && "bold"}><Link to="/CloudSecurity">Cloud Security</Link></li>
          <li className={"container" == selectedOne && "bold"}><Link to="/ContainerOrchestration">Container Orchestration</Link></li>
          <li className={"azure" == selectedOne && "bold"}><Link to="/Azure">Azure Cloud</Link></li>
          <li className={"google" == selectedOne && "bold"}><Link to="/GCP">Google Cloud</Link></li>
        </ul>
      </div>
      <div className="sidebar__item sidebar__item--cta">
        <div className="sidebar__cta"
          style={{ backgroundImage: `url(${BG})` }}>
          <i className="sidebar__cta__icon icon-phone-ringing"></i>
          <br/>
          <strong className="sidebar__cta__title" style={{fontSize:"25px"}}>Have Tech Problems
            Contact Now</strong>
            <br></br>
            <br></br>
          <p className="sidebar__cta__text">Call Anytime <br /><Link to="tel:051-8742 704">
            (051) 8742 704</Link></p>
        </div>
      </div>
      {/*<div className="sidebar__item sidebar__item--btn">
        <Link to="#" className="thm-btn sidebar__btn"><span>Download our flyers</span></Link>
      </div>*/}
    </div>
  )
}

export default ServiceSidebar