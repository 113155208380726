import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceSidebar from '../../Services/ServiceSidebar/Main'
import image from './Cloud Secutiy.png'
import image2 from './1692338917848.png'


function First() {

    const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "1. How does encryption enhance cloud security?",
			answer: "Encryption ensures that data remains secure by encoding it in a way that can only be accessed or decrypted with the right keys or credentials. In cloud security, robust encryption methods are used to protect sensitive information both during storage and transmission, mitigating the risks of unauthorized access or data breaches."
		},
		{
			question: "2. What is the role of multi-factor authentication (MFA) in cloud security?",
			answer: "Multi-factor authentication adds an extra layer of security by requiring multiple forms of verification before granting access. Typically, this involves combining something the user knows (like a password) with something they have (such as a code from a mobile device) or something unique to them (like a fingerprint). "
		}
	]

  return (
    <>
        <section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={image} alt="Cloud Security" />
								</div>
								<h1 className="service-details__title">Ensuring Robust Cloud Security Solutions</h1>
								<div className="service-details__content" style={{textAlign:"justify"}}>
									<p>Our tailored security protocols and strategic measures are designed to fortify 
									cloud-based infrastructures, protecting against potential threats and vulnerabilities. 
									With a proactive approach, we implement robust encryption, multi-factor authentication, 
									and continuous monitoring to ensure data integrity and confidentiality. Our expertise in 
									cloud security spans across diverse industries, providing peace of mind while enabling 
									businesses to harness the full potential of cloud technologies without compromising on 
									safety or compliance.</p>
									<h2 className="service-details__box__title">Fortifying Cloud Defenses</h2>
									<p>Secure your digital assets with our robust cloud security solutions. We specialize in 
									implementing proactive measures, including advanced encryption, continuous monitoring, 
									and multi-layered authentication protocols. Our tailored strategies ensure the safety and 
									integrity of your data, mitigating potential threats and vulnerabilities within cloud 
									environments. Partner with us to fortify your cloud defenses and safeguard your business 
									against evolving cybersecurity risks.</p>
								</div>
								
								<div className="row gutter-y-30">
									<div className="col-md-6 col-sm-12">
										<img src={image2} className="service-details__sub-image"
											alt="" />
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="service-details__caption">
											<h3 className="service-details__caption__title" style={{marginBottom:"10px"}}>Upsides of Cloud Security Measures</h3>
											<p className="service-details__caption__text" style={{marginBottom:"10px"}}>Robust cloud security protocols safeguard your digital assets, 
											ensuring data integrity and protection against potential threats.</p>
											<ul className="service-details__caption__list">
												<li>
													<i className="fa fa-check-circle"></i>
													Encryption and Access Control
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Continuous Monitoring and Threat Detection
												</li>
												<li>
													<i className="fa fa-check-circle"></i>
													Multi-factor Authentication
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="accrodion-grp service-details__accrodion"
											data-grp-name="service-details__accrodion-1">
											{faqs.map((item, index) => (
												<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
													<div className="accrodion-title">
														<h4 onClick={() => setClicked(index)}>{item.question}<span
															className="accrodion-icon"></span>
														</h4>
													</div>
													{index === clicked &&
														<div className="accrodion-content">
															<div className="inner">
																<p>{item.answer}</p>
															</div>
														</div>}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar selectedOne='cs'/>
							</div>
						</div>
					</div>
			</section>
    </>
  )
}

export default First