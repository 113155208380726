import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import BC from '../../Menservises/MenCS/BC'
import First from '../../Menservises/MenCS/First'
import { Helmet } from 'react-helmet-async'
const CloudSecurity = () => {

	return (
		<>
		<Helmet>
			<title>Cloud Security</title>
			<meta name="description" content="Our DevOps experts specialize in crafting cloud security solution that seamlessly integrate with cloud environment and robust protection for your digital assets"  data-rh="true"/>
        	<link rel="canonical" href="https://skyops.io/CloudSecurity"  />   
		</Helmet>
			<BC Title="Cloud Security" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default CloudSecurity