import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Data from './Data'
import { Helmet } from 'react-helmet-async'
const Saas= () => {
	
	return (
		<>
		<Helmet>
			<title>Project | SaaS</title>
			<meta name="description" content="Software as a Service (SaaS) application utilizing modern automation and deployment practices using IaC and CICD."  data-rh="true"/>
            <link rel="canonical" href="https://skyops.io/Saas" />  
		</Helmet>
		<div style={{lineHeight:"40px"}}>
			<Breadcumb Title= {<h1 style={{fontWeight:"bold", fontSize:"48px"}}>SaaS using IaC and CICD</h1>} Breadcumb="PROJECT" />
		</div>
			<Data/>
			
		</>
	)
}

export default Saas