import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Logo from '../../assets/images/twitter.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Helmet } from 'react-helmet';

const Testblog = ({ blog }) => {
    //console.log(blog);
    const [url, setUrl] = useState('');
    const [copied, setCopied] = useState(false); 

    useEffect(() => {
        const currentUrl = window.location.href;
        setUrl(currentUrl);
    }, []);

    // where blog might be undefined
    if (!blog) {
        return <div>No data found</div>;
    }
    
    const renderers = {
        h1: ({ node, ...props }) => <h1 className="blog-card-one__more" style={{ fontSize: '2rem', color: "blueviolet", }} {...props} />,
        h2: ({ node, ...props }) => <h2 style={{ fontSize: '1.75rem', color: "blueviolet" }} {...props} />,
        h3: ({ node, ...props }) => <h3 style={{ fontSize: '1.5rem', color: "blueviolet" }} {...props} />,
        p: ({ node, ...props }) => <p className="about-one__text" style={{ lineHeight: '1.7', fontSize:"18px", paddingTop:"10px"}} {...props} />,
        img: ({ node, ...props }) => <img style={{ width: "100%",height: "auto",objectFit: "cover"}} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            const codeString = React.Children.toArray(children).map(child => typeof child === 'string' ? child : '').join('');
            
            const handleCopy = () => {
                setCopied(true); // Set copied status to true
                setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
            };

            return !inline && match ? (
                <div style={{ position: 'relative', marginBottom: '20px' }}>
                    <CopyToClipboard text={codeString} onCopy={handleCopy}>
                        <button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                zIndex: 1,
                                padding: '5px',
                            }}
                        >
                            <i className="fas fa-copy" style={{fontSize:"20px"}}></i>
                        </button>
                    </CopyToClipboard>
                    {copied && ( // Show the "Copied!" message if copied is true
                        <span style={{ 
                            position: 'absolute', 
                            right: '40px', 
                            top: '10px', 
                            color: 'blueviolet', 
                            fontWeight: 'bold' 
                        }}>
                            Copied! <i className="fas fa-check"></i>
                        </span>
                    )}
                    <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                        <code className={className} {...props}>
                            {children}
                        </code>
                    </pre>
                </div>
            ) : (
                <code style={{ background: '#f5f5f5', color: '#d6336c', padding: '2px 4px', borderRadius: '4px' }} {...props}>
                    {children}
                </code>
            );
        }
    };
    const slugifyLib = require('slugify'); 
    const blogUrl = `https://skyops.io/blog/${slugifyLib(blog.title)}`;
    const ogImage = blog?.coverImg || '';
    console.log("URL:", blogUrl);
    console.log("Title:", blog.title);
    console.log("Description:", blog.description);
    console.log("Image:", blog.coverImg);
    return (
        <>
           <Helmet>
                <title>SkyOps | {blog.title}</title>
                <meta property="og:url" content={blogUrl} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={blog.title} />
                <meta property="og:description" content={blog.description.slice(0, 150)} />
                <meta property="og:image" content={blog.coverImg} />
                <meta property="og:image:width" content="900" />
                 <meta property="og:image:height" content="482" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content={blogUrl} />
                <meta name="twitter:title" content={blog.title} />
                <meta name="twitter:description" content={blog.description.slice(0, 150)} />
                <meta name="twitter:image" content={blog.coverImg} />
            </Helmet>
         <section className="section-padding--bottom section-padding--top d-flex">
            <div className="container ">
                <div className="project-details__info" style={{ width: "auto" }}>
                    <div className="project-details__info__item">
                        <strong className="fas fa-city" style={{ fontSize: "18px", color: "white" }}></strong>
                        <b className="project-details__info__title" style={{ marginLeft: "10px", fontSize: "18px" }}>Industry</b>
                        <p className="project-details__info__text">{blog.IndustryName}</p>
                    </div>
                    <div className="project-details__info__item">
                        <strong className="fas fa-user-edit" style={{ fontSize: "18px", color: "white" }}></strong>
                        <b className="project-details__info__title" style={{ marginLeft: "10px", fontSize: "18px" }}>Solutions</b>
                        <p className="project-details__info__text">{blog.Solutions}</p>
                    </div>
                    <div className="project-details__info__item">
                        <strong className="fa fa-handshake" style={{ fontSize: "18px", color: "white" }}></strong>
                        <b className="project-details__info__title" style={{ marginLeft: "10px", fontSize: "18px" }}>Partner</b>
                        <p className="project-details__info__text">{blog.PartnerName}</p>
                    </div>
                    <div className="project-details__info__item">
                        <div className="project-details__info__social">
                        <a href={blog.hyperlink} target="_blank" rel="noopener noreferrer">
                         <i className="fab fa-github"></i>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="row">
    <div className="col-12 d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-10">
            <h1 className="blog-card-one__title blog-details__title text-center text-md-start">{blog.title}</h1>
            <div className="blog-card-one__meta d-flex justify-content-center justify-content-md-start mb-3">
                <div className="blog-card-one__date">
                    <i className="fa fa-calendar-alt me-2" aria-hidden="true"></i>
                    {new Date(blog.date).toLocaleDateString()}
                </div>
            </div>
            <div className="blog-details__image text-center">
                <img src={blog.coverImg} alt={blog.title} className="img-fluid" />
            </div>

            <div className="pt-5 justify-text">
                <ReactMarkdown
                    children={blog.content}
                    remarkPlugins={[remarkGfm]}
                    components={renderers}
                />
            </div>
    <div className="row mt-5">
    <div className="col-12">
        <div className="sidebar d-flex flex-column flex-md-row align-items-center align-items-md-start w-100">
            <div className="sidebar__item sidebar__item--posts d-flex flex-column flex-md-row align-items-center flex-fill">
                <div className="comments-one__item__image mb-3 mb-md-0 me-md-3">
                    <img
                        src={blog.authorImg}
                        alt={blog.authorName}
                        className="img-fluid rounded-circle"
                        style={{ height: 150, width: 150 }}
                    />
                </div>
                <div className="text-center text-md-start">
                    <h3 className="sidebar__title mb-1" style={{ marginBottom: '0.5rem' }}>{blog.authorName}</h3>
                    <p className="sidebar__posts__description" style={{ marginBottom: '0' }}>{blog.authorDes}</p>
                </div>
            </div>
        </div>
    </div>
</div>

            <div className="blog-details__meta mt-4">
                <div className="blog-details__tags text-center">
                    <span>Share the Article</span>
                </div>
                <ul className="blog-details__share d-flex justify-content-center mt-2">
                    <li className="mx-2">
                        <TwitterShareButton url={blogUrl}>
                            <img src={Logo} style={{ height: 30, width: 30 }} alt="Twitter Share" />
                        </TwitterShareButton>
                    </li>
                    <li className="mx-2">
                        <FacebookShareButton url={blogUrl} style={{ color: "#316FF6", fontSize: "30px" }}>
                            <i className="fab fa-facebook"></i>
                        </FacebookShareButton>
                    </li>
                    <li className="mx-2">
                        <LinkedinShareButton url={blogUrl} style={{ color: "#0077B5", fontSize: "30px" }}>
                            <i className="fab fa-linkedin"></i>
                        </LinkedinShareButton>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
     </div>
        </section>
        </>
    );
}

export default Testblog;
