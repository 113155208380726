import React from 'react'
import { Link } from 'react-router-dom'
import project01 from '../../assets/images/projects/ECS APP.png'
import project02 from '../../assets/images/projects/LM.png'
import project03 from '../../assets/images/projects/CICd.png'
import project04 from '../../assets/images/projects/Data/4.png'
import project05 from '../../assets/images/projects/Data/5.png'
import project06 from '../../assets/images/projects/Data/6.png'
import { Helmet } from 'react-helmet-async'

function First() {
    return (
        <>
            <Helmet>
                 <title> SkyOps | Projects </title>
            </Helmet>
            <section className="section-padding--bottom section-padding--top">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="000ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                <img src={project01} alt="GenerativeAI" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solution</strong>
                                        <h1 className="project-card-one__title"><Link to="/GenerativeAI">Generative AI application deployment</Link></h1>
                                        <Link to="/GenerativeAI" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="100ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                <img src={project02} alt="Ecommerce" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solutions</strong>
                                        <h2 className="project-card-one__title"><Link to="/Ecommerce">CI/CD Pipeline for E-Commerce Application</Link></h2>
                                        <Link to="/Ecommerce" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="200ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                 <img src={project03} alt="Learning Management System" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solutions</strong>
                                        <h2 className="project-card-one__title"><Link to="/LMS">Learning Management System</Link></h2>
                                        <Link to="/LMS" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="300ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                <img src={project04} alt="Migration" />

                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solutions</strong>
                                        <h3 className="project-card-one__title"><Link to="/Migration">Migration from Heroku to AWS</Link></h3>
                                        <Link to="/Migration" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="400ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                <img src={project05} alt="SaaS" />

                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solutions</strong>
                                        <h3 className="project-card-one__title"><Link to="/Saas">SaaS using IaC and CICD</Link>
                                        </h3>
                                        <Link to="/Saas" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="500ms">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                <img src={project06} alt="E-Commerce Website" />

                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <strong className="project-card-one__text">DevOps & Cloud Solutions</strong>
                                        <h3 className="project-card-one__title"><Link to="/Web">E-Commerce Website Deployment using SaaS Model</Link></h3>
                                        <Link to="/Web" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First