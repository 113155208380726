import React from 'react'
import { Link } from 'react-router-dom'
import BG1 from '../../assets/images/background/aboutus_slider.jpg'
import { Parallax } from 'react-parallax'

function Bettertwo() {
    return (
        <>
            <section className="black-bg cta-two">
                <Parallax bgImage={BG1} bgImageAlt="" className="cta-two__bg section-padding-lg--top section-padding-lg--bottom" strength={300}>
                    <div className="container">
                        <div className="cta-two__inner">
                            <h2 className="cta-two__title">Unmatched DevOps Solutions
                                Just a  <span>Click Away</span></h2>
                            <Link to="/Contact" className="thm-btn cta-two__btn"><span>LEarn More</span></Link>
                        </div>
                    </div>
                </Parallax>
            </section>
        </>
    )
}

export default Bettertwo