import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import Business from '../../Menservises/Servisetwo/Business';
import Overcome from '../../Menhome/Overcome';
import Ready from '../../Menhome/Ready';
import Busi from '../../Menhome/Busi';
import { Helmet } from 'react-helmet-async';


const CloudServices = () => {
  
  return (
    <>
    <Helmet>
      <title> SkyOps | Cloud Services </title>
      <meta name="description" content="Embrace the Cloud Revolution with SkyOps. Navigate our Cloud Services to discover tailored solutions for your business into the future with our expert team."  data-rh="true"/>
      <link rel="canonical" href="https://skyops.io/CloudServices"  />   
    </Helmet>
      <Breadcumb Title="CLOUD CONSULTANCY" Breadcumb="SERVICES" />
      <Business/>
      <Overcome/>
			<Ready/>
    </>
  )
}

export default CloudServices