import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import BC from '../../Menservises/MenAzure/BC'
import First from '../../Menservises/MenAzure/First'
import { Helmet } from 'react-helmet-async'
const Azure = () => {

	return (
		<>
		<Helmet>
			<title>Azure Cloud</title>
			<meta name="description" content="Unleash the full potential of  Azure with our expert guidance, integration, scalability and performance. Your precision Azure DevOps solution begin with SkyOps."  data-rh="true"/>
       		 <link rel="canonical" href="https://skyops.io/Azure"  />  
		</Helmet>
			<BC Title="Azure Cloud" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default Azure