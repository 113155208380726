import React from 'react'
import Breadcumb from '../../Breadcumb/Main'
import About from '../../Menservises/Serviseone/About';
import Benifit from '../../Menservises/Serviseone/Benifit';
import Need from '../../Menhome/Need';
import Tool from '../../Menservises/Tool';
import { Helmet } from 'react-helmet-async';

const DevOpsServices = () => {

  return (
    <>
    <Helmet>
      <title> SkyOps | DevOps Services </title>
      <meta name="description"  content="Elevate Your DevOps Journey with SkyOps. We  provides Infrastructure as Code, CI/CD Pipeline, SaaS, and Container Orchestration services to our  trusted clients"  data-rh="true"/>
      <link rel="canonical" href="https://skyops.io/DevOpsServices"  />  
    </Helmet>
      <Breadcumb Title="DevOps Services" Breadcumb="SERVICES" />
      <About/>
      <Tool/>
      <Benifit/>
      <Need/>
    </>
  )
}

export default DevOpsServices