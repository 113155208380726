import React from 'react'
import { Link } from 'react-router-dom'
import BG3 from '../../assets/images/shapes/about-seven-bg-1-1.png'

function Busi() {
    return (
        <>
            <section className="section-padding--top section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-size-cover gray-bg about-seven"
                style={{ backgroundImage: `url(${BG3})` }}>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-5">
                            <div className="about-seven__content">
                                <div className="section-title">
                                    <p className="section-title__text">HOW CAN HELP YOU</p>
                                    <h4 className="section-title__title">Cloud Solutions <br /> to help in Consultation</h4>
                                </div>
                                <div className="about-seven__text" style={{textAlign:"justify"}}>Unlock the full potential of the cloud with our expert consultation, 
                                tailored to address your specific business needs. Our dedicated team guides you through strategic decision-making, 
                                ensuring a seamless and optimized transition to the cloud.</div>
                               {/* <div className="about-seven__btns">
                                    <Link to="/service-01" className="thm-btn about-seven__btn"><span>Learn More</span></Link>
                                </div>*/}

                            </div>
                        </div>
                        <div className="col-lg-7">
                            <ul className="about-seven__list">
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="000ms">
                                    <i className="about-seven__list__icon icon-dashboard"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Automated <br />
                                            Software</Link>
                                    </p>
                                </li>
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="100ms">
                                    <i className="about-seven__list__icon icon-system"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Managed IT <br />
                                            Services</Link>
                                    </p>
                                </li>
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="200ms">
                                    <i className="about-seven__list__icon icon-cloud-data"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Market <br />
                                            Strategy</Link>
                                    </p>
                                </li>
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="300ms">
                                    <i className="about-seven__list__icon icon-data-visualization"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Data <br />
                                            Structuring</Link>
                                    </p>
                                </li>
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="400ms">
                                    <i className="about-seven__list__icon icon-software-engineer"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Software <br />
                                            Develop</Link>
                                    </p>
                                </li>
                                <li className="about-seven__list__item text-center wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="500ms">
                                    <i className="about-seven__list__icon icon-web-design"></i>
                                    <p className="about-seven__list__title">
                                        <Link to="#">Website <br />
                                            Develop</Link>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Busi