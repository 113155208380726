import React from 'react'
import BC from '../../Menservises/MenContainer/BC'
import First from '../../Menservises/MenContainer/First'
import { Helmet } from 'react-helmet-async'
const ContainerOrchestration = () => {
	
	return (
		<>
	<Helmet>
		<title>Container Orchestration</title>
		<meta name="description" content="Explore and discover how SkyOps revolutionizes application management. Navigate through the orchestration of containers using Kubernetes or ECS with precision."  data-rh="true"/>
        <link rel="canonical" href="https://skyops.io/ContainerOrchestration"  />  
	</Helmet>
			<BC Title="Container Orchestration" Breadcumb="SERVICES" />
			<First/>
		</>
	)
}

export default ContainerOrchestration