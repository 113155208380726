import React from 'react'
import { Link } from 'react-router-dom'
import BgImg from  "../../assets/images/background/aboutus_header.jpg"

const Breadcumb = ({ Title, Breadcumb }) => {
    return (
        <div className="page-header">
            
            <div className="page-header__bg" 
                style={{ backgroundImage: `url(${BgImg})` }}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled" style={{marginBottom:"18px"}} >
                    <li><Link to="/">Home</Link></li>
                    <li>{Breadcumb && Breadcumb}</li>
                </ul>
                <strong className="page-header__title">{Title && Title}</strong>
            </div>
        </div>
    )
}

export default Breadcumb